@import "src/styles/variables";

.editor {
  width: 100%;
  .ck.ck-content {
    height: 55vh;
    font-family: "Times New Roman", sans-serif;
    color: #000000;
    pointer-events: auto !important;
    overflow: auto !important;
  }
}
